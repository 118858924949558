/** @jsx jsx */
import { jsx } from "theme-ui";
import * as React from "react";

import { Helmet } from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";

export const SetPageTitle = ({ title }) => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          author
        }
      }
    }
  `);

  const siteName = data.site.siteMetadata.title;

  return (
    <Helmet>
      <title>
        {title} | {siteName}
      </title>
    </Helmet>
  );
};

export const PageTitle = ({ title }) => {
  return (
    <div
      sx={{
        fontSize: "pageTitle",
        fontWeight: "heading",
        fontFamily: "heading",
      }}
    >
      {title}
    </div>
  );
};
