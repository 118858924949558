/** @jsx jsx */
import { jsx } from "theme-ui";

import Socials from "../socials";

export const Footer = () => {
  return (
    <div
      sx={{
        marginTop: "1em",
        marginBottom: "0.5em",
        width: "95vw",

        display: "flex",
        justifyContent: "flex-end",
        alignItems: "flex-end",
        gap: "0.2em",

        fontSize: "1.5em",
      }}
    >
      <Copyright />
      <div sx={{ fontSize: "1em" }}>
        <Socials />
      </div>
    </div>
  );
};

const Copyright = () => {
  return (
    <div
      sx={{
        marginRight: "auto",
        fontSize: "0.5em",
      }}
    >
      © {new Date().getFullYear()} Zheneng Xie. All Rights Reserved.
    </div>
  );
};
