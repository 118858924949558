/** @jsx jsx */
import { jsx, useThemeUI } from "theme-ui";
import * as React from "react";

import {
  Timeline,
  Container,
  YearContent,
  BodyContent,
  Section,
  Description,
} from "vertical-timeline-component-react";

const TimelineItem = ({ start, end, title, subtitle, description }) => {
  end = end ? { endDate: end } : { currentYear: true };

  return (
    <Container>
      <YearContent startDate={start} {...end} />
      <BodyContent>
        <Section title={title}>
          <Description variant="subtitle" text={subtitle} />
          <Description text={description} />
        </Section>
      </BodyContent>
    </Container>
  );
};

const AcademicTimeline = ({ timelineItems }) => {
  const { theme } = useThemeUI();
  const { text, primary, muted } = theme.colors;
  const customTheme = {
    yearColor: text,
    lineColor: muted,
    dotColor: primary,
    borderDotColor: primary,
    titleColor: primary,
    subtitleColor: text,
    textColor: text,
  };

  return (
    <div
      style={{
        height: "auto",
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div>
        <Timeline theme={customTheme} dateFormat="ll">
          {timelineItems.map(({ start, end, title, subtitle, description }) => (
            <TimelineItem
              key={start}
              start={start}
              end={end}
              title={title}
              subtitle={subtitle}
              description={description}
            />
          ))}
        </Timeline>
      </div>
    </div>
  );
};

export default AcademicTimeline;
