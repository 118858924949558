/** @jsx jsx */
import { jsx, Link } from "theme-ui";
import { graphql, useStaticQuery } from "gatsby";
import { Icon } from "@iconify/react";

const Socials = () => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          email
          github
          linkedin
          orcid
          universityPage
        }
      }
    }
  `);

  const { email, github, linkedin, orcid, universityPage } =
    data.site.siteMetadata;

  return (
    <div
      sx={{
        display: "flex",
        gap: "0.2em",
      }}
    >
      <Link href={`mailto:${email}`} title="Email Me">
        <Icon icon="fontisto:at" />
      </Link>
      <Link href={github} title="Github Profile">
        <Icon icon="akar-icons:github-fill" />
      </Link>
      <Link href={linkedin} title="LinkedIn Profile">
        <Icon icon="akar-icons:linkedin-fill" />
      </Link>
      <Link href={orcid} title="ORCID Profile">
        <Icon icon="cib:orcid" />
      </Link>
      <Link href={universityPage} title="College Profile">
        <Icon icon="fa-solid:university" />
      </Link>
    </div>
  );
};

export default Socials;
