/** @jsx jsx */
import { Link, jsx } from "theme-ui";
import { Icon } from "@iconify/react";

const COAUTHOR_DATABASE = {
  s_donderwinkel: {
    name: "Serte Donderwinkel",
    src: "https://www.sertedonderwinkel.com",
  },
  c_goldschmidt: {
    name: "Christina Goldschmidt",
    src: "http://www.stats.ox.ac.uk/~goldschm/",
  },
};

const ArxivPaper = ({ title, id, date, coAuthors }) => {
  return (
    <div>
      <Title title={title} />
      <Details title={title} id={id} date={date} coAuthors={coAuthors} />
    </div>
  );
};

const Title = ({ title }) => {
  return (
    <div
      sx={{
        marginBottom: "0.5em",
        fontWeight: "paperTitle",
      }}
    >
      {title}
    </div>
  );
};

const Details = ({ title, id, date, coAuthors }) => {
  return (
    <div
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <DetailRow icon="bi:calendar-event">{date}</DetailRow>
      <DetailRow icon="bi:link-45deg">
        <Link
          href={`https://arxiv.org/abs/${id}`}
          title="Link to preprint"
          aria-label={`link to preprint titled ${title}`}
        >
          {`arXiv:${id}`}
        </Link>
      </DetailRow>
      {coAuthors && (
        <DetailRow icon="bi:people">
          {"with "}
          {coAuthors.map((key, i) => (
            <span key={key}>
              {i > 0 && ", "}
              <Link href={COAUTHOR_DATABASE[key].src}>
                {COAUTHOR_DATABASE[key].name}
              </Link>
            </span>
          ))}
        </DetailRow>
      )}
    </div>
  );
};

const DetailRow = ({ icon, children }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "0.2em",
      }}
    >
      <Icon icon={icon} />
      {children}
    </div>
  );
};

export default ArxivPaper;
