/** @jsx jsx */
import { jsx, useColorMode } from "theme-ui";
import { m, useReducedMotion } from "framer-motion";
import { Icon } from "@iconify/react";
import { NavBar } from "./navbar";

export const TitleBar = () => {
  return (
    <div
      sx={{
        width: "95vw",

        paddingLeft: ["0", "titleBarSidePadding"],
        paddingRight: ["0", "titleBarSidePadding"],
        paddingTop: "1rem",
        paddingBottom: "1rem",

        marginTop: "1em",
        marginBottom: "1em",

        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexDirection: ["column", "column", "row"],
        gap: "1em",
      }}
    >
      <SiteTitle />
      <ColorToggleButton />
      <NavBar />
    </div>
  );
};

const SiteTitle = () => {
  return (
    <div
      sx={{
        fontSize: "siteTitle",
        fontFamily: "siteTitle",
        fontWeight: "siteTitle",
        textAlign: "center",
      }}
    >
      Zheneng Xie
    </div>
  );
};

const ColorToggleButton = () => {
  const [colorMode, setColorMode] = useColorMode();

  const toggleSymbol =
    colorMode === "light" ? (
      <Icon icon="bx:bx-sun" />
    ) : (
      <Icon icon="bx:bx-moon" />
    );

  const toggleColorMode = () => {
    const newColorMode = colorMode === "dark" ? "light" : "dark";
    setColorMode(newColorMode);
  };

  const prefersReducedMotion = useReducedMotion();

  return (
    <m.button
      sx={{
        color: "text",
        fontSize: "iconButton",

        background: "transparent",
        border: "none",

        display: "flex",
        placeItems: "center",
      }}
      transition={{ type: "spring", damping: 15, stiffness: 100 }}
      whileHover={prefersReducedMotion ? {} : { scale: 1.5, rotate: 360 }}
      onClick={() => toggleColorMode()}
      aria-label="toggle color mode"
    >
      {toggleSymbol}
    </m.button>
  );
};
