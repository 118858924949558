/** @jsx jsx */
import { jsx } from "theme-ui";

import { StaticImage } from "gatsby-plugin-image";

const Hello = () => {
  return (
    <div
      sx={{
        display: "grid",
        gridTemplateColumns: ["1fr", "2fr 1fr"],
        gridTemplateRows: ["1fr 1fr", "1fr"],
        alignItems: "center",

        marginBottom: "2em",
      }}
    >
      <GreetingMessage />
      <ProfilePic />
    </div>
  );
};

const GreetingMessage = () => {
  return (
    <div
      sx={{
        justifySelf: "stretch",

        display: "grid",
        alignItems: "center",
        justifyItems: ["center", "left"],

        fontFamily: "heading",
        fontSize: ["3em", "3.5em"],
        textAlign: "center",
      }}
    >
      <div>Hi!</div>
      <div>
        I'm <span sx={{ color: "primary" }}>Zheneng</span>.
      </div>
    </div>
  );
};

const ProfilePic = () => {
  return (
    <div
      sx={{
        justifySelf: ["center", "right"],
        width: ["10em", "100%"],
      }}
    >
      <StaticImage
        style={{ borderRadius: "50%" }}
        alt="profile picture"
        src="./profile.jpg"
        layout="constrained"
      />
    </div>
  );
};

export default Hello;
