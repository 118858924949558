/** @jsx jsx */
import { Link, jsx } from "theme-ui";

import * as React from "react";

import { nextItem } from "../../components/date_time";

import { InlineIcon } from "@iconify/react";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import advancedFormat from "dayjs/plugin/advancedFormat";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

export const NextDeadline = ({ deadlines, children }) => {
  const { dateTime, task } = nextItem(deadlines);

  const clientTimezone = dayjs.tz.guess();
  const date = dateTime.format("dddd Do MMMM");
  const time = dateTime.tz(clientTimezone).format("HH:mm z");

  return (
    <div
      sx={{
        padding: "1em 1em 0.15em",
        backgroundColor: "accent",
        borderRadius: "0.5em",
      }}
    >
      <div
        sx={{ fontWeight: "heading", fontSize: "1.2em", marginBottom: "0.2em" }}
      >
        Next Deadline
      </div>
      <div>
        <InlineIcon icon="carbon:time" /> {date}, {time}
      </div>
      <div>
        <InlineIcon icon="akar-icons:paper" /> {task}
      </div>
      {children}
    </div>
  );
};
