import NoTitleLayout from "../../../../src/components/no_title_layout";
import Socials from "../../../../src/components/socials";
import Hello from "../../../../src/pages/index_includes/_hello";
import AcademicTimeline from "../../../../src/pages/index_includes/_academic_timeline";
import * as React from 'react';
export default {
  NoTitleLayout,
  Socials,
  Hello,
  AcademicTimeline,
  React
};