/** @jsx jsx */
import { jsx } from "theme-ui";

import * as React from "react";
import { Helmet } from "react-helmet";

import { LazyMotion, domAnimation } from "framer-motion";

import { Footer } from "./layout_components/footer";
import { TitleBar } from "./layout_components/titlebar";

import "@fontsource/lato";
import "@fontsource/merriweather";

const Layout = ({ children }) => {
  return (
    <>
      <Helmet htmlAttributes={{ lang: "en" }}>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Zheneng Xie's website for research, teaching and outreach"
        />
      </Helmet>

      <div
        sx={{
          position: "relative",
          minHeight: "100vh",

          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <LazyMotion features={domAnimation}>
          <header>
            <nav>
              <TitleBar />
            </nav>
          </header>

          <main
            sx={{
              width: (theme) => `min(${theme.sizes.contentWidth}, 95%)`,
            }}
          >
            {children}
          </main>
          <footer sx={{ marginTop: "auto" }}>
            <Footer />
          </footer>
        </LazyMotion>
      </div>
    </>
  );
};

export default Layout;
