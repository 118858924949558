import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

export const parseDateTime = (dateTime) => dayjs.tz(dateTime, "Europe/London");

export const genDateTimes = (start, n = 3, gap = 2) => {
  const startDateTime = parseDateTime(start);

  let dateTimes = [];
  for (let i = 0; i < n; i++) {
    dateTimes.push(startDateTime.add(i * gap, "week"));
  }

  return dateTimes;
};

export const nextItem = (items) => {
  const current = dayjs();

  items.sort((item1, item2) => item1.dateTime.diff(item2.dateTime));

  return items.filter((item) => current.isBefore(item.dateTime))[0];
};
