// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-blog-posts-blog-post-1-mdx": () => import("./../../../src/blog_posts/blog/post1.mdx" /* webpackChunkName: "component---src-blog-posts-blog-post-1-mdx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-mdx": () => import("./../../../src/pages/blog/index.mdx" /* webpackChunkName: "component---src-pages-blog-index-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-levy-index-mdx": () => import("./../../../src/pages/levy/index.mdx" /* webpackChunkName: "component---src-pages-levy-index-mdx" */),
  "component---src-pages-research-index-mdx": () => import("./../../../src/pages/research/index.mdx" /* webpackChunkName: "component---src-pages-research-index-mdx" */),
  "component---src-pages-teaching-index-mdx": () => import("./../../../src/pages/teaching/index.mdx" /* webpackChunkName: "component---src-pages-teaching-index-mdx" */)
}

