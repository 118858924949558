import * as React from "react";

import { SetPageTitle } from "./page_title";

const NoTitleLayout = ({ pageContext, children }) => {
  const { title } = pageContext.frontmatter;

  return (
    <>
      <SetPageTitle title={title} />
      {children}
    </>
  );
};

export default NoTitleLayout;
