const theme = {
  breakpoints: ["40rem", "56rem"],
  colors: {
    text: "white",
    background: "#1C1C1C",
    primary: "#44cf6c",
    secondary: "#cf44a8",
    accent: "#C20114",
    muted: "#8f8f8f",
    modes: {
      light: {
        text: "#2b2b2b",
        background: "#FFFCF2",
        primary: "#BA274A",
        accent: "#FF8C42",
        muted: "#8f8f8f",
      },
    },
  },
  config: {
    initialColorModeName: "dark",
  },
  fonts: {
    body: "Lato, Arial",
    heading: "Merriweather, Georgia",
    siteTitle: "Merriweather, Georgia",
  },
  fontSizes: {
    body: "1.1rem",
    iconButton: "1.8rem",
    pageTitle: "2rem",
    navBar: "1.2rem",
    siteTitle: "3rem",
  },
  fontWeights: {
    body: 400,
    heading: 700,
    siteTitle: 700,
    paperTitle: 600,
  },
  sizes: {
    contentWidth: "38rem",
  },
  space: {
    titleBarSidePadding: "2rem",
  },
  styles: {
    root: {
      fontFamily: "body",
      fontWeight: "body",
      fontSize: "body",
    },
    h1: {
      fontFamily: "heading",
      fontSize: "1.5rem",
    },
    h2: {
      fontFamily: "heading",
      fontSize: "1rem",
    },
    a: {
      color: "text",
      fontFamily: "body",
      textDecoration: "underline",
      ":hover": {
        color: "primary",
      },
    },
    img: {
      boxshadow: "none",
    },
    li: {
      listStyle: "none",
      "::before": {
        content: '"• "',
        color: "primary",
        display: "block",
        position: "relative",
        left: "-1.7em",
        top: "-0.1em",
        maxWidth: 0,
        maxHeight: 0,
      },
    },
    em: {
      color: "primary",
      fontStyle: "normal",
    },
  },
};

export default theme;
