/** @jsx jsx */
import { jsx, useThemeUI } from "theme-ui";
import { Link } from "gatsby";
import { InlineIcon } from "@iconify/react";

export const NavBar = () => {
  return (
    <div
      sx={{
        display: "flex",

        alignItems: "center",
        justifyContent: "center",
        gap: "1.5em",

        fontSize: "navBar",
      }}
    >
      <NavItem to="/" text="Home" icon="bx:bx-home-alt" />
      <NavItem
        to="/research"
        text="Research"
        icon="majesticons:academic-cap-line"
      />
      <NavItem to="/teaching" text="Teaching" icon="line-md:pencil" />
    </div>
  );
};

const NavItem = ({ to, text, icon }) => {
  const { theme } = useThemeUI();

  return (
    <div>
      <Link
        sx={{
          background: "transparent",

          color: "text",
          textDecoration: "none",
          fontSize: "navBar",
          textAlign: "center",

          ":hover": {
            textDecoration: "underline",
          },

          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexWrap: "wrap",
          gap: "0.2em",
        }}
        activeStyle={{
          color: theme.colors.primary,
          textDecoration: "underline",
        }}
        to={to}
      >
        <InlineIcon icon={icon} /> {text}
      </Link>
    </div>
  );
};
